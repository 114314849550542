import React, { useContext } from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import Layout from "../components/layout"
import LanguageContext from "../contexts/LanguageContext"
import { styles } from "../utils"

const PortfolioTemplate = ({ data }) => {
  //const { lang } = useContext(LanguageContext)

  return (
    <Layout>
      <TitleWrapper>
        <h2>{data.contentfulPortfolio.title}</h2>
      </TitleWrapper>
      <PortfolioText>
        {data.contentfulPortfolio.description.description}
      </PortfolioText>
      <NoUnderlineLink
        href={data.contentfulPortfolio.url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <LinkButton>{"visiter"}</LinkButton>
      </NoUnderlineLink>
      <ImageWrapper>
        <Img fluid={data.contentfulPortfolio.image.fluid} />
      </ImageWrapper>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    contentfulPortfolio(id: { eq: $id }) {
      title
      image {
        fluid {
          ...GatsbyContentfulFluid_tracedSVG
        }
      }
      description {
        description
      }
      url
    }
  }
`

export default PortfolioTemplate

const TitleWrapper = styled.div`
  font-size: 2rem;
  text-align: center;
  margin: 2rem 0;
`

const ImageWrapper = styled.div`
  max-width: 40rem;
  margin: 2rem auto;
`

const PortfolioText = styled.p`
  text-align: center;
  padding: 2rem;
  white-space: pre-wrap;
`

const LinkButton = styled.button`
  text-transform: uppercase;
  text-decoration: none;
  padding: 1.5rem 4rem;
  display: block;
  border-radius: 10rem;
  transition: all 0.2s;
  position: relative;
  letter-spacing: 1px;
  border: none;
  cursor: pointer;
  background-color: ${styles.colors.primary};
  font-family: "Nunito", sans-serif;
  color: white;
  font-weight: 100;
  margin: 1rem auto;
  max-width: 20rem;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
    &:hover::after {
      transform: scaleX(1.4) scaleY(1.6);
      opacity: 0;
    }
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-1px);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3);
  }
  &::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 10rem;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    transition: all 0.4s;
    background-color: ${styles.colors.primary};
  }
`

const NoUnderlineLink = styled.a`
  text-decoration: none;
`
